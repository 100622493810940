import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './request'
import auth from './config';
import 'bootstrap';
import moment from 'vue-moment'
import VCalendar from 'v-calendar';
import 'regenerator-runtime/runtime'
Vue.use(VCalendar, {
    componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});
Vue.use(moment);

Vue.config.productionTip = false

Vue.filter('toHMTime', function (value) {
    var minutes = Math.floor((value) / 60);
    var seconds = value - (minutes * 60);

    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return minutes + ':' + seconds;
});


new Vue({
    router,
    store,
    auth: auth,
    http: axios,
    render: h => h(App)
}).$mount('#app')
