import Vue from 'vue'
import VueRouter from 'vue-router'
import App from "@/components/App";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: App,
        meta: {
            auth: true,
            name: 'test',
        },
        children: [
            // {
            //     path: '',
            //     name: 'Dashboard',
            //     component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
            // },
            {
                path: '/404',
                name: 'NotFound',
                meta: {
                    auth: true,
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
            },
            {
                path: 'releases',
                name: 'ReleaseCalendar',
                component: () => import(/* webpackChunkName: "about" */ '../views/ReleaseCalendar.vue')
            },
            {
                path: 'profile',
                name: 'Profile',
                props: route => ({ ...route.query, ...route.params }),
                component: () => import(/* webpackChunkName: "about" */ '../views/Profile/Profile.vue')
            },
            {
                path: '',
                name: '',
                component: {
                    render(h) {
                        return h('router-view');
                    }
                },
                children:[
                    {
                        path: '',
                        name: 'Classes',
                        component: () => import(/* webpackChunkName: "about" */ '../views/Classes.vue')
                    },
                    {
                        path: 'create',
                        name: 'CreateClass',
                        component: () => import(/* webpackChunkName: "about" */ '../views/Class/AddClass.vue')
                    },
                    {
                        path: ':id(\\d+)',
                        name: 'ClassDetails',
                        component: () => import(/* webpackChunkName: "about" */ '../views/Class/ClassDetails'),
                        props: true,
                    },
                    {
                        path: ':id(\\d+)/edit',
                        name: 'EditClass',
                        component: () => import(/* webpackChunkName: "about" */ '../views/Class/AddClass'),
                        props: true,
                    },
                    {
                        path: ':id(\\d+)/audio',
                        name: 'ClassAudio',
                        component: () => import(/* webpackChunkName: "about" */ '../views/Class/AudioUpload.vue'),
                        props: true,
                    },
                    {
                        path: ':id(\\d+)/segments',
                        name: 'ClassSegments',
                        component: () => import(/* webpackChunkName: "about" */ '../views/Class/AudioSegmentationV2.vue'),
                        props: true,
                    },
                    // {
                    //     path: ':id(\\d+)/segment',
                    //     name: 'ClassSegments',
                    //     component: () => import(/* webpackChunkName: "about" */ '../views/Class/AudioSegmentationV2.vue'),
                    //     props: true,
                    // },
                ]
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            auth: false,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/Login.vue')
    },
    {
        path: '/sign-up',
        name: 'SignUp',
        meta: {
            auth: false,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/Register.vue')
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        meta: {
            auth: false,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/ForgotPassword.vue')
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        meta: {
            auth: false,
        },
        props: route => ({ ...route.query, ...route.params }),
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/ResetPassword.vue')
    },

]

Vue.router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default Vue.router
